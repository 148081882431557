<template>
  <Preview class="mb-3"/>
  <CategoriesList class="mb-20" />
  <About />
  <NewItems />
</template>

<script setup>
import Preview from "../components/index/Preview";
import CategoriesList from "../components/index/CategoriesList";
import About from "../components/index/About";
import NewItems from "~/components/index/NewItems.vue";
</script>
